import { template as template_93bab2a94dac4645967666ec7df9bd8c } from "@ember/template-compiler";
const FKControlMenuContainer = template_93bab2a94dac4645967666ec7df9bd8c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
