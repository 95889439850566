import { template as template_5f4a0d0688364b6bb225063507b69fd1 } from "@ember/template-compiler";
const WelcomeHeader = template_5f4a0d0688364b6bb225063507b69fd1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
